.chatMarkdown {
  margin: 3px;
  margin-bottom: 10px;
}

.chatMarkdown ol,
ul {
  margin-left: 10px;
  font-size: var(--font-regular);
}
.chatMarkdown ol li {
  list-style: decimal;
  list-style-position: inside;
}
.chatMarkdown ul li {
  list-style: circle;
  list-style-position: inside;
}

/* to align list number and the paragraph for it on the same line */
.chatMarkdown ol li > p {
  display: inline;
  margin-left: 5px;
}
.chatMarkdown ul li > p {
  display: inline;
  margin-left: 0;
}

.chatMarkdown h1,
h2,
h3,
h4 {
  font-weight: 600;
  font-size: 15px;
}

.chatMarkdown hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.chatMarkdown table,
th {
  border-bottom: 1px solid;
  padding: 2px;
}
.chatMarkdown td {
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px dashed darkgray;
  padding: 1px 2px 1px 3px;
}

.chatMarkdown pre {
  background: var(--DropDownMenuBackgroundColor);
  border: 1px solid;
  padding: 7px;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
}

.chatMarkdown blockquote {
  border-left: 10px solid #ccc;
  margin: 1em 5px;
  padding: 0.5em 5px;
  font-style: italic;
  position: relative;
}

.chatMarkdown.blockAlert blockquote {
  border-color: var(--alertColor);
}

.chatMarkdown.blockAlert blockquote > blockquote {
  border-color: var(--primaryColor);
}
